import { Box, Divider, useTheme } from '@mui/material';
import { BaseButton } from 'components/atoms/Buttons';
import { LargeText, MediumText, NormalText, SmallText } from 'components/atoms/Typography';
import moment from 'moment';
import { getDetailsRouterArguments } from 'services/application';
import { BaseStatus, PrimaryStatus } from 'components/atoms/Status';
import { CartItems, Order } from 'klikni-jadi-shared-stuff';
import { OrderStatus } from 'klikni-jadi-shared-stuff';
import { Restaurant } from 'klikni-jadi-shared-stuff';
import { useCartService } from 'services/cart/useCartService';
import { useRouting } from 'services/routing';
import upperCase from 'lodash/upperCase';
import values from 'lodash/values';
import toPairs from 'lodash/toPairs';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useDataTranslator } from 'hooks/useDataTranslator';
import { Timestamp } from '@firebase/firestore-types';
import Render from 'components/atoms/Render';
import { getCartItemVisiblePrice, getOrderPrices } from 'services/utils';

interface LastOrderDetailsProps {
    date: Timestamp;
    orderNo: string;
    status: OrderStatus;
    restaurant: Partial<Restaurant>;
    items: CartItems;
    orderPrice: number;
    order?: Order;
}

const LastOrderDetails = ({ date, orderNo, status, restaurant, orderPrice, items, order }: LastOrderDetailsProps) => {
    const cartService = useCartService();
    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();
    const { deliveryFee, discount, itemsTotal, priorityFee, roundup, tip, total, walletMoney } = getOrderPrices(order);
    const routing = useRouting();
    const handleOrderAgain = async () => {
        // for (const item of values(items)) {
        //     const mappedExtras = item.extras?.map(extra => ({ id: extra.id, quantity: extra.quantity })) || [];
        //     await cartService.addItem({ ...item, extras: mappedExtras }, restaurant);
        // }

        await cartService.addItems(values(items), restaurant);

        routing.push({ to: '/cart' });
    };

    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: '10px',
                // boxShadow: theme.shadows[5],
                pt: 2,
                pb: 3,
                px: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    pt: 2,
                    pb: 3,
                    px: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                    <LargeText color={theme.palette.primary.main} semibold>
                        {t('orderDetails')}
                    </LargeText>
                    <Box pt={1}>
                        <NormalText>
                            {t('date')}: <b>{moment(date.toDate()).format('DD MMMM YYYY')}</b>
                            ,&nbsp;&nbsp;
                            {moment(date.toDate()).format('HH:mm')}
                        </NormalText>
                    </Box>

                    <Box pt={1} display="flex" justifyContent="center">
                        <SmallText semibold color="primary" sx={{ mx: 1 }}>
                            {upperCase(t('onlineOrder'))}
                        </SmallText>
                        <Render when={order.type === 'takeout'}>
                            <SmallText semibold color="primary" sx={{ mx: 1 }}>
                                {upperCase(t('selfPickup'))}
                            </SmallText>
                        </Render>
                    </Box>
                    <Box display="flex" mt={1}>
                        <Box px={1}>
                            <PrimaryStatus>ID #{orderNo}</PrimaryStatus>
                        </Box>
                        <Box px={1}>
                            {' '}
                            <BaseStatus color="secondary">{t(status)}</BaseStatus>
                        </Box>
                    </Box>
                </Box>
                <Box width="100%" flex={1} minHeight={0} display="flex" flexDirection="column" justifyContent="space-between">
                    <Box
                        sx={{
                            border: '1px solid #D8D8D8',
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        mt={4}
                        p={2}
                        minHeight={0}
                        overflow="auto"
                    >
                        <Box pb={1}>
                            <MediumText>{restaurant?.name && translate(restaurant?.name).toUpperCase()}</MediumText>
                        </Box>
                        {toPairs(items).map(([id, item]) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                key={id}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box sx={{ paddingRight: '10px' }}>
                                        <NormalText sx={{ paddingRight: '5px' }}> x{item.quantity}</NormalText>
                                        <NormalText semibold>{translate(item.name)}</NormalText>
                                    </Box>

                                    <Box width="auto">
                                        <NormalText semibold>
                                            {getCartItemVisiblePrice(item, order.type)} {t('mkd')}
                                        </NormalText>
                                    </Box>
                                </Box>
                                {item?.extras?.length > 0 && (
                                    <Box>
                                        <NormalText sx={{ fontSize: '0.9rem', color: '#6D7783' }}>
                                            {t('extra', { count: 2 })}:{' '}
                                            <b>
                                                {item?.extras
                                                    ?.map(ex => `${ex.quantity * item.quantity} x ${translate(ex?.name)}`)
                                                    .join(', ')}
                                            </b>
                                        </NormalText>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>

                    <Box>
                        <Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} px={2} mt={3}>
                                <Box>
                                    <NormalText>{t('itemsTotal')}</NormalText>
                                </Box>
                                <Box>
                                    <NormalText>
                                        {itemsTotal} {t('mkd')}
                                    </NormalText>
                                </Box>
                            </Box>
                            <Render when={!!roundup}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                    px={2}
                                >
                                    <Box>
                                        <NormalText>{t('roundup')}</NormalText>
                                    </Box>
                                    <Box>
                                        <NormalText>
                                            {roundup} {t('mkd')}
                                        </NormalText>
                                    </Box>
                                </Box>
                            </Render>
                            <Render when={!!deliveryFee}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                    px={2}
                                >
                                    <Box>
                                        <NormalText>{t('delivery')}</NormalText>
                                    </Box>
                                    <Box>
                                        <NormalText>
                                            {deliveryFee} {t('mkd')}
                                        </NormalText>
                                    </Box>
                                </Box>
                            </Render>
                            <Render when={!!priorityFee}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} px={2}>
                                    <Box>
                                        <NormalText>{t('priority')}</NormalText>
                                    </Box>
                                    <Box>
                                        <NormalText>
                                            {priorityFee} {t('mkd')}
                                        </NormalText>
                                    </Box>
                                </Box>
                            </Render>
                            <Render when={!!tip}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} px={2}>
                                    <Box>
                                        <NormalText>{t('tip', { count: 1 })}</NormalText>
                                    </Box>
                                    <Box>
                                        <NormalText>
                                            {tip} {t('mkd')}
                                        </NormalText>
                                    </Box>
                                </Box>
                            </Render>
                            <Render when={!!walletMoney}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} px={2}>
                                    <Box>
                                        <NormalText>
                                            {t('from')} {t('wallet')}
                                        </NormalText>
                                    </Box>
                                    <Box>
                                        <NormalText>
                                            {walletMoney} {t('mkd')}
                                        </NormalText>
                                    </Box>
                                </Box>
                            </Render>
                            <Render when={!!discount}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} px={2}>
                                    <Box>
                                        <NormalText>{t('discount')}</NormalText>
                                    </Box>
                                    <Box>
                                        <NormalText>
                                            {discount} {t('mkd')}
                                        </NormalText>
                                    </Box>
                                </Box>
                            </Render>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} px={2} mt={2}>
                            <Box>
                                <NormalText semibold>{t('total')}</NormalText>
                            </Box>
                            <Box>
                                <NormalText semibold>
                                    {orderPrice} {t('mkd')}
                                </NormalText>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box width="100%" pt={2}>
                    <Divider />
                </Box>
                <Box width="100%" pb={2} display="flex" flexDirection="column" alignItems="center">
                    <Box width="200px" pt={4}>
                        <BaseButton fullWidth variant="primary" onClick={handleOrderAgain}>
                            {t('orderAgain')}
                        </BaseButton>
                    </Box>
                    <Box width="200px" pt={3}>
                        <Link href={getDetailsRouterArguments(restaurant.id, restaurant?.name?.en)}>
                            <a>
                                <BaseButton fullWidth variant="secondary" color="primary">
                                    {t('seeRestaurant')}
                                </BaseButton>
                            </a>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default LastOrderDetails;
